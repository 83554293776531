<template>
  <div class="container py-5">
    <p class="fw-bold">第63屆國際美博會</p>
    <p>時間：2024/03/10-12</p>
    <p>地點：中國進出口商品交易會展館</p>
    <p>展位：B區12.2館H12A號</p>

    <p>
     OBSERV 520X皮膚檢測儀是根據當今醫學美容顧問的需求打造而成，是OBSERV旗下推出的最新產品。
    <br><br>
    • 搭配專利的臉部定位系統透過視覺的回饋科技偵測客戶臉部位置<br>
    • 透過不同的專利LED光源及多重偏振光科技檢測皮膚表層和深層達到更清楚準確的效果<br>
    • 將醫學美容譫詢服務轉變為客戶的互動式體驗<br>
    • 規劃完善的、結果導向的療程和客戶討論最適合的優先順序<br>
    • 100%安全無害的分析方式<br>
    • 模組化的硬體設計，零耗材
    <br><br>
    專利臉部定位系統<br>
    • 為了採集到最完美的標準化影像，必須要有臉部自動對焦功能。 我們的人體工學顎墊，可以調整五種角度；且OBSERV 520x app在譫詢過程中，會快速找出所選的角度。<br>
    • OBSERV 520x搭配專利的臉部定位系統（Face Positioning System，FPS），透過視覺的回饋科技，更輕易地偵測到客戶臉部的位置。
    <br><br>
    新技術的發展<br>
    • OBSERV紫外光技術通過安全認證：對人體皮膚眼睛無害
    <br><br>
    手部年輕化評估系統<br>
    • 創新手部標準化影像擷取<br>
    • 創新手部膚色/膚齡/膚質評估<br>
    • 創新手部老化評估<br>
    </p>

    <img
      class="img-md rounded"
      src="@/assets/images/zh-TW/news/new_35.jpg"
    />


  </div>
</template>
<script>
export default {};
</script>
